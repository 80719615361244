import NotFoundPage from "@/components/errors/404";
import ErrorBoundary from "@/components/errors/ErrorBoundary";
import Auth from "@/pages/Auth";
import EmailVerification from "@/pages/EmailVerification";
import ForgotPassword from "@/pages/ForgotPassword";
import Home from "@/pages/Home";
import Program from "@/pages/Program";
import Programs from "@/pages/Programs";
import ResetPassword from "@/pages/ResetPassword";
import { Route, Routes } from "react-router-dom";

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ErrorBoundary>
            <Home />
          </ErrorBoundary>
        }
      />
      <Route
        path="/submit/"
        element={
          <ErrorBoundary>
            <Home />
          </ErrorBoundary>
        }
      />
      <Route
        path="/submit/program/:id"
        element={
          <ErrorBoundary>
            <Program />
          </ErrorBoundary>
        }
      />
      <Route
        path="/submit/programs"
        element={
          <ErrorBoundary>
            <Programs />
          </ErrorBoundary>
        }
      />
      <Route
        path="/submit/login"
        element={
          <ErrorBoundary>
            <Auth />
          </ErrorBoundary>
        }
      />
      <Route
        path="/submit/register/verify"
        element={
          <ErrorBoundary>
            <EmailVerification />
          </ErrorBoundary>
        }
      />
      <Route
        path="/submit/forgot-password"
        element={
          <ErrorBoundary>
            <ForgotPassword />
          </ErrorBoundary>
        }
      />
      <Route
        path="/submit/reset-password"
        element={
          <ErrorBoundary>
            <ResetPassword />
          </ErrorBoundary>
        }
      />
      <Route
        path="*"
        element={
          <ErrorBoundary>
            <NotFoundPage isPageChildren={false} />
          </ErrorBoundary>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
